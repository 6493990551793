import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./views/Login";
import Register from "./views/Register";

const root = ReactDOM.createRoot(document.getElementById("root"));
document.title="Label Desk"
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
        <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register} />
        <Route component={Login} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);
