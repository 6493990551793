import React, { useState } from "react";

export default function LoginForm() {
  const validateData = (event) => {
    event.preventDefault();
    let canSend = true;

    if (!event.target.email.value || !event.target.password.value) {
      canSend = false;
    }

    if (canSend) {
      console.log("CAN SEND");
    }

    setValidData(canSend);
  };

  const displayInvalidField = () => {
    if (!validData)
      return (
        <p className="invalid">Invalid username and/or password</p>
      );
    else return <></>;
  };

  const [validData, setValidData] = useState(true);
  return (
    <div className="container" style={{ marginBottom: "60px" }}>
      <form className="form" onSubmit={validateData}>
        <div className="form-row">
          <h2 style={{ color: "white" }}>Please, Login</h2>
        </div>
        <div className="form-row">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            className="input"
          ></input>
        </div>
        <div className="form-row">
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            className="input"
          ></input>
        </div>{" "}
        <a href="/forgot" className="forgot-password">
          Forgot Password?
        </a>
        {displayInvalidField()}
        <div className="form-row" style={{justifyContent: 'start', marginLeft: '10%', marginRight:'auto', marginTop: '-20px'}}>
          <input
            id="keep-logged"
            name="keep-logged"
            type="checkbox"
            className="input"
            style={{width:'12px', padding: '0'}}
          ></input><p style={{color:"white", fontSize: '12px'}}>Keep me logged in</p>
        </div>{" "}
        <div className="form-row">
          <button type="submit" className="button">
            Login
          </button>
        </div>
        <div className="form-row">
          <p style={{ color: "white" }}>Are you new to Label Desk?</p> <br />
        </div>
        <div className="form-row" style={{ marginTop: "-20px" }}>
          <a href="/register" className="register-cta">
            Create an Account
          </a>
        </div>
      </form>
    </div>
  );
}
