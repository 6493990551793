import React, { useEffect } from "react";
import LoginForm from "../components/LoginForm";

export default function Login() {

  useEffect(() => {
    document.title = "Label Desk | Login";
  });

  return (
    <>
      <div className="bg-login">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: "40px",
          }}
        >
          <img src="/assets/img/logo.png" className="logo" alt="logo" width="300px" height="300px"></img>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <LoginForm></LoginForm>
        </div>
      </div>
    </>
  );
}
