import React, { useEffect } from "react";
import RegisterForm from "../components/RegisterForm";

export default function Register() {

  useEffect(() => {
    document.title = "Label Desk | Register";
  });

  return (
    <>
      <div className="bg-login">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <img src="/assets/img/logo.png" alt="logo" className="logo" width="300px" height="300px"></img>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row"
          }}
        >
          <RegisterForm></RegisterForm>
        </div>
      </div>
    </>
  );
}
