import React from "react";
import { useState } from "react";

export default function RegisterForm() {
  const [invalidFields, setInvalidFields] = useState([]);

  const displayInvalidField = (fieldName) => {
    let displayedFieldName;
    if (fieldName === "repeatpassword") {
      displayedFieldName = "Repeat Password";
    } else {
      displayedFieldName = fieldName[0].toUpperCase() + fieldName.slice(1);
    }
    if (invalidFields.includes(fieldName)) {
      return <p className="invalid">{displayedFieldName} can't be blank</p>;
    } else {
      return <></>;
    }
  };

  const validateData = (event) => {
    event.preventDefault();
    let canSend = true;
    let invalidFieldsArray = [];
    if (!event.target.name.value) invalidFieldsArray.push("name");
    if (!event.target.surname.value) invalidFieldsArray.push("surname");
    if (!event.target.email.value) invalidFieldsArray.push("email");
    if (!event.target.password.value && event.target.password.value.length < 6)
      invalidFieldsArray.push("password");
    if (
      !event.target.repeatpassword.value ||
      event.target.repeatpassword === !event.target.password.value
    )
      invalidFieldsArray.push("repeatpassword");

    console.log(invalidFieldsArray);
    setInvalidFields(invalidFieldsArray);
    if (canSend) {
      console.log("CAN SEND");
    }
  };

  return (
    <>
      <div className="container" style={{ marginBottom: "60px" }}>
        <form className="form" onSubmit={validateData}>
          <div className="form-row">
            <h2 style={{ color: "white" }}>Create Account</h2>
          </div>
          <div className="form-row">
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Name *"
              className="input"
              required
            ></input>
          </div>
            {displayInvalidField("name")}
          <div className="form-row">
            <input
              id="surname"
              name="surname"
              type="text"
              placeholder="Surname *"
              className="input"
              required
            ></input>
          </div>
            {displayInvalidField("surname")}
          <div className="form-row">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email *"
              className="input"
              required
            ></input>
          </div>
            {displayInvalidField("email")}
          <div className="form-row">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password *"
              className="input"
              required
            ></input>
          </div>
            {displayInvalidField("password")}
          <div className="form-row">
            <input
              id="repeatpassword"
              name="repeatpassword"
              type="password"
              placeholder="Repeat Password *"
              className="input"
              required
            ></input>
          </div>
            {displayInvalidField("repeatpassword")}
          <div className="form-row">
            <button type="submit" className="button" style={{marginTop: '10px'}}>
              Register
            </button>
          </div>
          <div className="form-row">
            <p style={{ color: "white" }}>Are you already a member?</p> <br />
          </div>
          <div className="form-row" style={{ marginTop: "-20px" }}>
            <a href="/login" className="register-cta">
              Login
            </a>
          </div>
        </form>
      </div>
    </>
  );
}
